.SignUp {

    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;

    .SignUpBox {
        background-color: #fefefe;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 40vh;
        min-width: 40vw;
        align-items: center;
        filter: drop-shadow(0 0 5px white);

        .SignUpTitle {
            font-size: 4vh;
            padding: 1vmin;
            margin: 0;
        }


        .SignUpBoxIconDivImg {

            max-width: 100%;
            max-height: 10vh;
        }




        .NameForm {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            .NameFormInput {
                display: flex;
                flex-direction: row;
                padding: 1vmin 1vmin;

                .NameFormLabel {
                    color: orange;
                    font-weight: 500;
                }
            }

            .NameFormButton {
                color: white;
                background-color: orange;
                border-radius: 8px;
                transition-duration: 0.4s;
                padding: .5vmin 1vmin;
                border: none;
                cursor: pointer;


            }

            .NameFormButton:hover {
                background-color: lightgreen;
            }
        }
    }
}