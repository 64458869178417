.UsersRequests {
    display: flex;
    flex-direction: column;
    height: 20vh;

    .UsersRequestsHouse {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .UsersRequestsHouseAddLogoDiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 5vh;

            .UsersRequestsHouseAddLogo {
                height: 5vh;

                max-width: 100%;
                max-height: 100%;
            }

            .UsersRequestsHouseAddLogo:hover {
                cursor: pointer;
                filter: invert(.5)
            }
        }
    }



}

.UserRequestHouseDiv {
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .UsersHouseLogo {

        max-width: 100%;
        max-height: 100%;
    }


}