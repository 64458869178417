.HousesUserBelongsToSectionDiv {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
}

.HousesUserBelongsToSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    .HousesUserBelongsToHouses {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 5vmin;

        .HouseDiv {
            display: flex;
            flex-direction: column;
            align-items: center;

            .UsersHouseLogo {
                max-width: 100%;
                height: 5vh;
            }

            .UsersHouseName {
                font-size: 1.5vmin;
                padding: .5vmin;
            }

        }

        .HouseDiv:hover {
            cursor: pointer;
            filter: invert(.5)
        }
    }

    .HousesUserBelongsToButtons {
        display: flex;
        flex-direction: column;
        padding-right: 1vmin;

        .HousesUserBelongsToButtonLogo {
            max-height: 5vmin;
            max-width: 100%;
        }

        .HousesUserBelongsToButtonLogo:hover {
            cursor: pointer;
            filter: invert(.5)
        }
    }
}