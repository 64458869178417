.HousePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .HousePageContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;

        .HousePageHeader {
            padding: 0;
            margin: 0;
            font-size: 4vh;
        }
    }

}