.UserPage {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;


    .AddUserLogo {
        max-width: 100%;
        max-height: 100%;
        height: 15vh;
    }


    .UsersIncompleteTasks {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        overflow-y: auto;
        max-height: 32vh;
    }


    .UsersCompleteTasks {
        width: 100%;
        height: 60vh;
        overflow-y: auto;
    }
}