.SmallUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-all;
    padding-left: 1%;
    padding-right: 1%;
    width: 10vw;

    .SmallUserLogo {
        max-width: 100%;
        max-height: 5vh;
    }

    .SmallUserName {
        text-align: center;
        word-wrap: break-word;
    }

}

.ShortenedSmallUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-all;
    padding-left: 1%;
    padding-right: 1%;

    .SmallUserLogo {
        max-width: 100%;
        max-height: 5vh;
    }

    .SmallUserName {
        text-align: center;
        word-wrap: break-word;

    }

}