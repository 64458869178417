.TaskTypeCardAllProperties {
    display: flex;
    flex-direction: column;


    .TaskTypeCardAllPropertiesName {
        margin: 0;
        padding: 1vmin;
        font-size: 2vmin;
    }

    .TaskTypeCardAllPropertiesHouse {
        margin: 0;
        padding: 1vmin;
        font-size: 1.5vmin;
    }


    .TaskTypeCardProperties {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: fit-content;
    }
}