@use "./SignUp.scss";
@use "./App.scss";
@use "./User.scss";
@use "./Modal.scss";
@use "./Login.scss";
@use "./Alerts.scss";
@use "./AddHouse.scss";
@use "./House.scss";
@use "./UserHeader.scss";
@use "./Leaderboard.scss";
@use "./SmallUser.scss";
@use "./AddUserToHouse.scss";
@use "./HousesLookup.scss";
@use "./UserRequests.scss";
@use "./LoadingWithHeader.scss";
@use "./HousesUserBelongsTo.scss";
@use "./TaskTypes.scss";
@use "./Tasks.scss";
@use "./ScheduleTaskFromTaskTypeModal.scss";
@use "./TaskTypeCard/index.scss";
@use "./TaskItemCard/TaskItemCard.scss";
@use "./ImageUploader.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.FlexHorizontalBreak {
    min-width: 100%;
}