.App {
    text-align: center;

}

.Loadinglogo {
    height: 40vmin;
    pointer-events: none;
}

@keyframes Loading-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .Loadinglogo {
        animation: Loading-logo-spin infinite 20s linear;
    }
}

.LoadingLogoDiv {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: gainsboro;
}