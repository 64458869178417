.AddHousePage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .AddHouseButtons {
        display: flex;
        width: 50vw;
        height: 70vh;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow-x: auto;


        .AddHouseButtonsLogo {
            height: 20vmin;
            width: 20vmin;
            max-height: 100%;
            max-width: 100%;
        }

        .AddHouseButtonsLogoNoHover {
            height: 20vmin;
            width: 20vmin;
            max-height: 100%;
            max-width: 100%;
        }




        .AddHouseButtonsLogo:hover {
            cursor: pointer;
            filter: invert(.5)
        }
    }

    .HouseNameForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .HouseNameFormInput {
            display: flex;
            flex-direction: column;
            align-items: center;

            .HouseNameFormLabel {
                color: orange;
                font-weight: 500;
            }
        }

        .HouseNameFormButton {
            color: white;
            background-color: orange;
            border-radius: 8px;
            transition-duration: 0.4s;
            padding: .5vmin 1vmin;
            border: none;
            cursor: pointer;


        }

        .HouseNameFormButton:hover {
            background-color: lightgreen;
        }
    }
}