.LeaderboardDiv {
    width: 100%;
}

.Leaderboard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10vh;

    .LeaderboardUsers {
        display: flex;
        flex-direction: row;
        width: 30%;
        justify-content: flex-start;
        align-items: center;
        padding-left: .5vmin;
        padding-right: .5vmin;
    }

    .LeaderboardAddUserDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 15%;

        .LeaderboardAddUserLogo {
            max-width: 100%;
            max-height: 5vh;
        }

        .LeaderboardAddUserLogo:hover {
            cursor: pointer;
            filter: invert(.5)
        }
    }

}