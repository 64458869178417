@use "./TaskTypeProperties.scss";
@use "./TaskTypeProperty.scss";

.TaskTypeCard {
    flex: 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    border: solid;

    .TaskTypeCardActions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1vh;

    }

    .TaskTypeCardButton {
        width: fit-content;
    }
}