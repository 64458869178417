.HousesLookupPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .HousesLookupPageContent {
        display: flex;
        flex-direction: column;

        .HousesLookupPageHousesSection {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

}

.HousesLookupHouseDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10vh;

    .HousesLookupHouseDivLogo {
        max-width: 100%;
        max-height: 100%;
    }

    .HousesLookupHouseDivLogo:hover {
        cursor: pointer;
        filter: invert(.5)
    }
}