.ImageUploader {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ImageUploaderTitle {
        font-size: 1vmin;
    }

    .ImageUploaderImage {
        height: 15vh;
        width: fit-content;
        max-width: 15vw;
    }

    .ImageUploaderRemoveImage {
        height: 2vh;
        max-width: 3vw;
        width: fit-content;

    }

    .ImageUploaderInput {
        display: none;
    }
}