.TaskTypesSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 30vh;
    width: 100%;

    .TaskTypesHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 6vh;

        .TaskTypeHeaderLeftPlaceholder {
            width: 33%
        }



        .TaskTypesHeaderTitle {
            margin: 0;
            padding: 0;
            font-size: 3vh;
        }





        .TaskTypesHeaderButtons {
            width: 33%
        }

    }



}

.TaskTypeContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 30vh;

}

.NoTaskTypesDisplay {
    color: gray;
    margin: 0;
    padding: 0;
}

.TaskTypeCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    row-gap: 5vmin;
    column-gap: 1vmin;
    width: 100vw;
    flex-wrap: wrap;
    overflow: auto;
}




.CreateTaskTypeModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .CreateTaskTypeModalContentRow {
        display: flex;
        flex-direction: row;
    }
}

.CreateTaskTypeProperty {
    display: flex;
    flex-direction: column;
    padding: 1vmin;

    .CreateTaskTypePropertyName {
        padding: 0;
        margin: 0;
    }

    .CreateTaskTypePropertyInputDescription {
        height: 5vh;

    }
}