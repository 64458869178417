.AddUserToHousePage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .AddUserToHousePageContent {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100vw;

        .AddUserToHousePageUsers {
            display: flex;
            flex-direction: column;
            align-items: center;

            .AddUserToHousePageUsersUserDiv {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 30vw;

                .AddUserToHousePageUsersSmallUser {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                }

                .LeaderboardAddUserDiv {
                    height: 15vw;
                    display: flex;
                    align-items: center;

                    .LeaderboardAddUserLogo {
                        height: 5vw;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    .LeaderboardAddUserLogo:hover {

                        cursor: pointer;
                        filter: invert(.5)
                    }
                }
            }
        }

        .AddUserToHousePageHouseRequests {
            display: flex;
            flex-direction: column;
            align-items: center;

            .AddUserToHousePageUsersUserDiv {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 30vw;

                .LeaderboardAddUserDiv {
                    height: 15vw;
                    display: flex;
                    align-items: center;

                    .LeaderboardAddUserLogo {
                        height: 5vw;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    .LeaderboardAddUserLogo:hover {

                        cursor: pointer;
                        filter: invert(.5)
                    }
                }
            }
        }
    }
}