.TaskTypeCardProperty {
    display: flex;
    flex-direction: column;
    height: 10vh;

    .TaskTypeCardPropertyLogo {
        height: 5vh;
    }

    .TaskTypeCardPropertyValue {
        padding: 0;
        margin: 0;
    }
}