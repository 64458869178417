.TaskSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 35vh;

    .TasksHeaderTitle {
        margin: 0;
        padding: 0;
        font-size: 3vh;
    }

}

.NoTasksDisplay {
    color: gray;
    margin: 0;
}

.TaskItemCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    row-gap: 5vmin;
    width: 100%;
    column-gap: 1vmin;
    flex-wrap: wrap;
    overflow: auto;
}