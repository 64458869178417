.TaskItemCard {
    flex: 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    border: solid;
    align-items: center;

    .TaskItemCardCompleteLogo {
        max-height: 2.5vh;
        padding: .5vh
    }

}


.TaskItemCardDateAndTimeHeader {
    padding: 0;
    font-size: small;

    margin: 0;
}

.TaskItemCardActions {
    height: fit-conten;
    padding: 1vmin;
}