.AlertsDiv {

    display: flex;
    flex-direction: column;
    position: fixed;
    /* Sit on top */
    top: 0;
    left: 33%;
    right: 0;
    bottom: 0;
    opacity: 1;
    pointer-events: none;

    width: 50vw;

    .AlertDiv {
        display: flex;
        pointer-events: visible;


        .AlertError {
            color: red
        }
    }
}